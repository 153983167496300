<template>
  <div class="home">
    <Temp msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Temp from '@/components/Temp.vue'

export default {
  name: 'Home',
  components: {
    Temp
  }
}
</script>
